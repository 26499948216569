import { useSelector, useDispatch } from "react-redux";
import { Stack, Typography } from "@mui/material";

import { setError, updateUserPassword } from "../../store/users/user-slice";
import { AppDispatch, RootState } from "../../store/store";
import { CenterModel } from "../../models/CenterModel";

import ChangePasswordIcon from "../../assets/images/icons/change-password.svg";
import HiddenTextInput from "../common/HiddenTextInput";

type Props = {
  login: string;
  accountType: "User" | "Admin" | "Client";
};

const UpdatePassword = ({ login, accountType }: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const center: CenterModel | null = useSelector(
    (store: any) => store.CENTER.currentCenter,
  );
  const error: string | null = useSelector(
    (store: RootState) => store.USER.error,
  );

  const updatePasswordAction = (newPassword: string) => {
    if (center) {
      dispatch(
        updateUserPassword(
          center.organizationPid,
          accountType,
          login,
          newPassword,
        ),
      );
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center" gap={1.5}>
      <img
        src={ChangePasswordIcon}
        width={50}
        style={{ margin: "auto" }}
        alt="Icône Mot de Passe"
      />
      <Typography variant="subtitle2">
        <b>Login CTonline :</b>
        <br /> {login}
      </Typography>
      <HiddenTextInput
        inputName="password"
        buttonText="Modifier le mot de passe"
        label="Nouveau mot de passe"
        setError={() => dispatch(setError(null))}
        errorMessage={error}
        successMessage="Le mot de passe a bien été modifié"
        buttonStyle={{ width: "fit-content", marginTop: "-0.5rem" }}
        onSubmitFunction={updatePasswordAction}
      />
    </Stack>
  );
};

export default UpdatePassword;
