import { createSlice } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";

// SLICE
export const accountSlice = createSlice({
  name: "accountSlice",
  initialState: {
    currentAccount: {
      isAdministrator: undefined,
    },
    loading: true,
    error: null,
  },
  reducers: {
    setIsSuperAdministrator: (currentSlice, action) => {
      currentSlice.currentAccount.isAdministrator = action.payload;
    },
    setLoading: (currentSlice, action) => {
      currentSlice.loading = action.payload;
    },
    setError: (currentSlice, action) => {
      currentSlice.error = action.payload;
    },
  },
});

export const { setIsSuperAdministrator, setLoading, setError } =
  accountSlice.actions;

// ACTIONS
export const checkIsSuperAdministrator =
  (externalUserId) => async (dispatch) => {
    await api
      .get(
        `/${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.USER}/${externalUserId}`,
      )
      .then((response) => {
        if (response.data.item && response.data.item.isAdministrator === true) {
          dispatch(setIsSuperAdministrator(true));
        } else {
          dispatch(setIsSuperAdministrator(false));
        }
      });
  };
