import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  Checkbox,
  Stack,
  TableRow,
  TableRowProps,
  Typography,
} from "@mui/material";
import {
  Grid,
  TableHeaderRow,
  SearchPanel,
  Toolbar,
  VirtualTable,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-material-ui";
import {
  IntegratedFiltering,
  IntegratedSorting,
  SearchState,
  SortingState,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";

import { AppDispatch, RootState } from "../../store/store";
import { CenterModel } from "../../models/CenterModel";
import { setCurrentCenter } from "../../store/centers/center-slice";
import limitedIcon from "../../assets/images/icons/red-limited.svg";

import LoadingProgress from "../common/LoadingProgress";
import HomeCard from "../common/HomeCard";
import ActivitiesIcons from "../common/ActivitiesIcons";

const CentersTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

  const data: CenterModel[] = useSelector(
    (store: RootState) => store.CENTERS.centersList,
  );

  const navigateToCenter = (code: string) => {
    if (code) {
      const currentCenter: CenterModel | undefined = data.find(
        (center: CenterModel) => code === center.pid,
      );
      dispatch(setCurrentCenter(currentCenter));
      navigate("/center");
    } else {
      setShowErrorMessage(true);
    }
  };
  useEffect(() => {
    showErrorMessage &&
      setTimeout(() => {
        setShowErrorMessage(false);
      }, 2500);
  }, [showErrorMessage]);

  const columns = [
    {
      name: "name",
      columnName: "name",
      title: "Nom du centre",
      width: 500,
      maxWidth: 500,
    },
    {
      name: "codePts",
      columnName: "codePts",
      title: "PTS",
      width: 110,
      maxWidth: 110,
    },
    {
      name: "city",
      columnName: "city",
      title: "Ville",
      width: 180,
      maxWidth: 200,
    },
    {
      name: "agreements",
      columnName: "agreements",
      title: "Agrément",
      width: 120,
      maxWidth: 180,
    },
    {
      name: "activities",
      columnName: "activities",
      title: "Activités",
      width: 100,
      maxWidth: 100,
    },
    {
      name: "isLimited",
      columnName: "isLimited",
      title: "Limité",
      width: 100,
      maxWidth: 100,
    },
    {
      name: "isFoxChecker",
      columnName: "isFoxChecker",
      title: "Fox Checker",
      width: 130,
      maxWidth: 130,
    },
    {
      name: "hasEarlyAccessVersion",
      columnName: "hasEarlyAccessVersion",
      title: "Early Access",
      width: 130,
      maxWidth: 130,
    },
  ];

  return (
    <>
      {data ? (
        <HomeCard width={{ sm: "100%", xl: "90%" }}>
          {showErrorMessage && (
            <Typography
              sx={{
                width: "fit-content",
                margin: "0 auto",
                color: "red",
              }}
              fontWeight="bold"
            >
              {"Ce centre n'a pas de PID"}
            </Typography>
          )}
          <Stack
            sx={{
              width: { xs: "100%", xl: "95%" },
              margin: "auto",
              height: "40rem",
              overflowX: "hidden",
            }}
          >
            <Grid rows={data} columns={columns}>
              <SearchState />
              <IntegratedFiltering />
              <SortingState />
              <IntegratedSorting />
              <DataTypeProvider
                for={["activities"]}
                formatterComponent={({ value }) => (
                  <ActivitiesIcons activities={value} width={20} />
                )}
              />
              <DataTypeProvider
                for={["isLimited"]}
                formatterComponent={({ value }) =>
                  value && (
                    <img
                      src={limitedIcon}
                      alt="Icône Limité"
                      title="Accès à CTonline limité"
                      width={25}
                    />
                  )
                }
              />
              <DataTypeProvider
                for={["agreements"]}
                formatterComponent={({ value }) =>
                  value.map((element: any) => (
                    <p key={element.agreement} title={element.activity}>
                      {element.agreement}
                    </p>
                  ))
                }
              />
              <DataTypeProvider
                for={["isFoxChecker"]}
                formatterComponent={({ value }) =>
                  value && (
                    <Checkbox
                      color="success"
                      checked
                      size="medium"
                      title="Organisation Fox Checker"
                    />
                  )
                }
              />
              <DataTypeProvider
                for={["hasEarlyAccessVersion"]}
                formatterComponent={({ value }) =>
                  value && (
                    <Checkbox
                      color="success"
                      checked
                      size="medium"
                      title="Early Access activé"
                    />
                  )
                }
              />
              <VirtualTable
                messages={{ noData: "Aucun centre trouvé" }}
                rowComponent={({ row, ...restProps }) => (
                  <TableRow
                    {...restProps}
                    onClick={() => navigateToCenter(row.pid)}
                    title={row.isClosed ? "Ce centre est fermé" : undefined}
                    sx={{
                      opacity: row.isClosed ? 0.4 : 1,
                      cursor: "pointer",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "rgba(46,30,76,0.3)",
                      },
                    }}
                  />
                )}
              />
              <TableColumnResizing
                defaultColumnWidths={columns}
                columnExtensions={columns}
              />
              <TableHeaderRow
                showSortingControls
                messages={{ sortingHint: "Trier" }}
                rowComponent={({ style, ...restProps }: TableRowProps) => (
                  <TableRow
                    {...restProps}
                    style={{
                      ...style,
                      backgroundColor: "#ea6927",
                      color: "#FFFFF",
                      width: "100%",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  />
                )}
              />
              <Toolbar />
              <SearchPanel
                messages={{ searchPlaceholder: "Rechercher un centre..." }}
              />
            </Grid>
          </Stack>
        </HomeCard>
      ) : (
        <LoadingProgress
          color="white"
          textVariant="h6"
          message="Chargement des centres..."
        />
      )}
    </>
  );
};

export default CentersTable;
