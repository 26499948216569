import { Divider, Stack, Typography } from "@mui/material";

import { UserModel } from "../../models/UserModel";
import { AzureUserModel } from "../../models/AzureUserModel";

import AzureInfo from "./AzureInfo";
import UpdatePassword from "./UpdatePassword";

type Props = {
  CTOnlineData: UserModel;
  AzureData: AzureUserModel | null;
};

const UserInfo = ({ CTOnlineData, AzureData }: Props) => {
  return (
    CTOnlineData && (
      <Stack>
        <Stack direction="row" justifyContent="center" gap={0.5}>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "primary.main" }}
          >
            {CTOnlineData.firstName} {CTOnlineData.lastName}
          </Typography>
        </Stack>
        <>
          <AzureInfo
            login={CTOnlineData.login}
            mail={AzureData?.mail}
            externalUserId={CTOnlineData.externalUserId}
            accountType={CTOnlineData.accountType}
            userPid={CTOnlineData.pid}
          />
          <Divider sx={{ m: "1rem 0" }} />
        </>
        <UpdatePassword
          login={CTOnlineData.login}
          accountType={CTOnlineData.accountType}
        />
      </Stack>
    )
  );
};

export default UserInfo;
