import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";

import { AppDispatch, RootState } from "../../store/store";
import {
  addAdministrator,
  setError,
} from "../../store/administrators/administrators-slice";

import HiddenTextInput from "../common/HiddenTextInput";

const AddAdministrator = () => {
  const dispatch: AppDispatch = useDispatch();

  const updateAdministrator = (mail: string) => {
    dispatch(addAdministrator(mail));
  };

  const error: string | null = useSelector(
    (store: RootState) => store.PROTECH_USERS.error,
  );

  return (
    <Stack
      marginTop="1rem"
      justifyContent="center"
      alignItems="center"
      gap={1.5}
    >
      <HiddenTextInput
        inputType="email"
        inputName="mail"
        label="E-mail de l'utilisateur"
        successMessage="L'administrateur a bien été ajouté."
        errorMessage={error}
        setError={() => dispatch(setError(null))}
        buttonText="Ajouter un administrateur"
        onSubmitFunction={updateAdministrator}
        variantButton="contained"
        buttonStyle={{
          width: "fit-content",
          backgroundColor: "secondary.main",
          marginTop: "-0.5rem",
          "&:hover": {
            backgroundColor: "secondary.main",
          },
        }}
      />
    </Stack>
  );
};

export default AddAdministrator;
