import { Grid } from "@mui/material";
import Tile from "../components/common/Tile";

import PaletteIcon from "../assets/images/icons/palette.svg";
import { NavigateFunction, useNavigate } from "react-router-dom";

const CTonline = () => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <>
      <Grid margin="1rem auto" width={"fit-content"} container gap={6}>
        <Tile
          title="Couleurs des partenaires"
          icon={PaletteIcon}
          onClick={() => navigate("/ctonline/partners")}
        />
      </Grid>
    </>
  );
};

export default CTonline;
