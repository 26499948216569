import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { AppDispatch } from "../store/store";
import { fetchUsers } from "../store/centers/center-slice";
import { fetchCenters } from "../store/organizations/organization-slice";
import { CONFIG_API } from "../data/config.API";
import { CenterModel } from "../models/CenterModel";

import HomeCard from "../components/common/HomeCard";
import CenterInfo from "../components/center/CenterInfo";

const Center = () => {
  const dispatch: AppDispatch = useDispatch();

  const centerInfo: CenterModel | null = useSelector(
    (store: any) => store.CENTER.currentCenter,
  );

  useEffect(() => {
    if (centerInfo) {
      // Get Center Users
      dispatch(
        fetchUsers(
          centerInfo.organizationPid,
          centerInfo.pid,
          CONFIG_API.ADMIN,
        ),
      );
      // Get Center Administrator
      dispatch(
        fetchUsers(centerInfo.organizationPid, centerInfo.pid, CONFIG_API.USER),
      );
      // Get Organization Centers
      dispatch(fetchCenters(centerInfo.organizationPid));
    }
  }, [centerInfo, dispatch]);

  return centerInfo ? (
    <HomeCard>
      <CenterInfo data={centerInfo} />
    </HomeCard>
  ) : (
    <Navigate to="/" />
  );
};

export default Center;
