import * as React from "react";
import { Button, Menu, MenuItem, Stack } from "@mui/material";

import BasicMenuItem, { MenuItemModel } from "./BasicMenuItem";

type Props = {
  children: React.ReactNode;
  items: MenuItemModel[];
  positionY: number | "bottom" | "center" | "top";
  positionX: number | "left" | "center" | "right";
  beforeText?: React.JSX.Element;
  afterText?: React.JSX.Element;
};

const BasicMenu = ({
  children,
  positionY,
  positionX,
  beforeText,
  afterText,
  items,
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ textTransform: "none" }}
      >
        {children}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: positionY,
          horizontal: positionX,
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {beforeText && <Stack mt={"-0.5rem"}>{beforeText}</Stack>}
        {items.map((item: MenuItemModel) => (
          <MenuItem key={items.indexOf(item)}>
            <BasicMenuItem
              type={item.type}
              icon={item.icon}
              iconAlt={item.iconAlt}
              setAnchorEl={setAnchorEl}
              onClick={item.onClick}
              text={item.text}
            />
          </MenuItem>
        ))}
        {afterText && <Stack p={"0 0.5rem"}>{afterText}</Stack>}
      </Menu>
    </div>
  );
};

export default BasicMenu;
