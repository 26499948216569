import { useEffect } from "react";
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";

import { AppDispatch, RootState } from "../store/store";
import { getUser } from "../store/users/user-slice";
import { UserModel } from "../models/UserModel";
import { AzureUserModel } from "../models/AzureUserModel";

import HomeCard from "../components/common/HomeCard";
import UserInfo from "../components/user/UserInfo";

const User = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const currentUser: UserModel = useSelector(
    (store: any) => store.USER.currentUser.CTonlineInfo,
  );
  const userAzureInfo: AzureUserModel | null = useSelector(
    (store: RootState) => store.USER.currentUser.AzureInfo,
  );

  useEffect(() => {
    if (currentUser) {
      dispatch(getUser(currentUser.externalUserId));
    }
  }, [dispatch, currentUser]);

  return currentUser ? (
    <HomeCard padding="2rem 6rem">
      <UserInfo CTOnlineData={currentUser} AzureData={userAzureInfo} />
      <Button
        sx={{ width: "fit-content", margin: "1rem auto 0" }}
        variant="contained"
        onClick={() => navigate("/center")}
      >
        <b>RETOUR AU CENTRE</b>
      </Button>
    </HomeCard>
  ) : (
    <Navigate to="/" />
  );
};

export default User;
