import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Avatar, Divider, Stack, Typography } from "@mui/material";

import { MenuItemModel } from "./common/BasicMenuItem";
import LogoutIcon from "../assets/images/icons/logout.svg";
import packageJson from "../../package.json";

import BasicMenu from "./common/BasicMenu";

const AuthUserInfo = () => {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // LOGOUT
  const handleLogout = async () => {
    await instance.logout({
      authority: process.env.REACT_APP_AZURE_BASE_URL,
      postLogoutRedirectUri: `/`,
    });
  };

  // GENERATE INITIALS AVATAR FROM USER NAME
  const stringAvatar = (
    fullName: string,
  ): { children: string | React.JSX.Element } => {
    if (fullName) {
      // Split the full name by spaces
      const parts = fullName.split(" ");
      // To handle names with multiple words (like "Jean Claude")
      // We will consider the first word as the first name and the last one as the surname
      const firstName = parts.length > 0 ? parts[0] : "";
      const lastName = parts.length > 1 ? parts[parts.length - 1] : "";

      let initiales = "";
      if (firstName) initiales += firstName[0].toUpperCase();
      if (lastName) initiales += lastName[0].toUpperCase();

      return {
        children: initiales,
      };
    } else {
      return {
        children: "P",
      };
    }
  };

  const menuItems: MenuItemModel[] = [
    {
      text: "Déconnexion",
      icon: LogoutIcon,
      iconAlt: "Icône Déconnexion",
      onClick: handleLogout,
    },
  ];

  return inProgress === "none" && isAuthenticated ? (
    <Stack
      width={{ xs: "100%", md: "100%" }}
      margin={{ xs: "auto", md: "0" }}
      justifyContent="center"
      alignItems="flex-end"
      paddingRight={{
        xs: "0",
        sm: "1rem",
      }}
      title={`${accounts[0].username}`}
    >
      <BasicMenu
        positionY="bottom"
        positionX="right"
        beforeText={
          <Stack
            sx={{
              backgroundColor: "primary.light",
              padding: "1rem",
              marginBottom: "0.5rem",
            }}
          >
            <Typography
              sx={{ wordBreak: "break-word", textAlign: "center" }}
              variant="subtitle2"
              fontSize={"0.9rem"}
            >
              {accounts[0].username}
            </Typography>
          </Stack>
        }
        afterText={
          <>
            <Divider />
            <Typography
              padding="0.5rem 0 0"
              textAlign={"end"}
              fontSize={"0.8rem"}
            >
              Version {packageJson.version}
            </Typography>
          </>
        }
        items={menuItems}
      >
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          height="100%"
          sx={{ cursor: "pointer" }}
        >
          <Avatar
            {...stringAvatar(`${accounts[0].name}`)}
            sx={{
              width: "2.2rem",
              height: "2.2rem",
              backgroundColor: "primary.main",
              fontSize: "0.9rem",
            }}
          />
          <Typography color={"secondary.main"} fontWeight={"bold"}>
            {accounts[0].name}
          </Typography>
        </Stack>
      </BasicMenu>
    </Stack>
  ) : null;
};

export default AuthUserInfo;
