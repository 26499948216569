import { useState } from "react";
import { Button, Chip, Stack } from "@mui/material";
import { AxiosResponse } from "axios";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";
import { APIResponseModel } from "../../models/ApiResponseList";
import ColorPicker, { ColorPickerModel, colorVariants } from "./ColorPicker";

export interface PartnerModel {
  pid: string;
  defaultColorHsl: string;
  name: string;
}

interface Props extends PartnerModel {
  fetchAllPartners: () => Promise<void>;
  setMessage: React.Dispatch<
    React.SetStateAction<{
      type: "success" | "error";
      text: string;
    }>
  >;
}

const PartnerCard = ({
  pid,
  defaultColorHsl,
  name,
  setMessage,
  fetchAllPartners,
}: Props) => {
  const [currentColor, setCurrentColor] = useState<ColorPickerModel>(
    colorVariants.find((color) => color.name === defaultColorHsl) ||
      colorVariants[0],
  );
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  const colorSubmit = async () => {
    const response: AxiosResponse<APIResponseModel<PartnerModel>> =
      await api.put(
        `${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.PARTNER}/${pid}`,
        {
          defaultColorHsl: currentColor?.name ?? "blue",
        },
      );
    if (response.data.success) {
      updateMessage("success", "La couleur du partenaire a bien été modifiée.");
      fetchAllPartners();
      setShowColorPicker(false);
    } else {
      updateMessage(
        "error",
        "Une erreur est survenue lors de la modification de la couleur du partenaire.",
      );
    }
  };

  const updateMessage = (type: "success" | "error", text: string) => {
    setMessage({ type: type, text: text });
    setTimeout(() => {
      setMessage({ type: "success", text: "" });
    }, 2500);
  };

  return (
    <Stack
      width="16rem"
      height={showColorPicker ? "20rem" : "16rem"}
      sx={{
        padding: "0.5rem 1rem",
        margin: "auto",
        backgroundColor: "white",
        boxShadow:
          "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
        borderRadius: "1rem",
        transition: "all 0.2s ease",
      }}
    >
      <Chip
        label={name}
        sx={{
          borderRadius: 0,
          width: "fit-content",
          backgroundColor: currentColor?.hexColor50,
          color: currentColor?.hexColor700,
          margin: "0.5rem auto",
          "&:hover": {
            color: currentColor?.hexColor700,
            transition: "all 0.5s ease",
          },
        }}
      />
      <Stack
        sx={{
          width: "8rem",
          height: "8rem",
          backgroundColor: currentColor?.hexColor700,
          transition: "all 0.5s ease",
          cursor: "pointer",
          margin: "1rem auto",
          borderRadius: "2rem",
          padding: "0.3rem 0.2rem",
          "&:hover": {
            backgroundColor: currentColor?.hexColor50,
            border: `1px solid ${currentColor?.hexColor700}`,
            transition: "all 0.2s ease",
          },
        }}
        onClick={() => {
          setShowColorPicker(!showColorPicker);
          setCurrentColor(
            colorVariants.find((color) => color.name === defaultColorHsl) ||
              colorVariants[0],
          );
        }}
        title={!showColorPicker ? "Modifier la couleur" : "Annuler"}
      />
      {showColorPicker && (
        <Stack gap={2} width="100%">
          <ColorPicker
            currentColor={
              colorVariants.find((color) => color.name === defaultColorHsl) ||
              colorVariants[0]
            }
            setCurrentColor={setCurrentColor}
          />
          <Button
            variant="contained"
            sx={{ margin: "auto", textTransform: "none", fontWeight: "bold" }}
            onClick={colorSubmit}
          >
            Valider
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default PartnerCard;
