import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CloseIcon from "../../assets/images/icons/cross.svg";

const CancelButton = () => {
  const navigate = useNavigate();

  // Redirect on "Escape" Key Press (to close HomeCard)
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        navigate("/");
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => {
      window.removeEventListener("keydown", handleEscape);
    };
  }, [navigate]);

  return (
    <img
      src={CloseIcon}
      tabIndex={0}
      alt=""
      onClick={() => {
        navigate("/");
      }}
      width={30}
      style={{ cursor: "pointer" }}
    />
  );
};

export default CancelButton;
