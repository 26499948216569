import { Button, Stack, Typography } from "@mui/material";
import ValidateButton from "./ValidateButton";

export type MenuItemModel = {
  type?: "text" | "button" | "validateButton";
  icon?: string;
  iconAlt?: string;
  variantButton?: "outlined" | "contained" | "text";
  text: string;
  setAnchorEl?: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  onClick: () => void;
};

const BasicMenuItem = ({
  text,
  icon,
  iconAlt,
  onClick,
  setAnchorEl,
  variantButton = "outlined",
  type = "text",
}: MenuItemModel) => {
  const handleClick = () => {
    onClick();
    setAnchorEl && type !== "validateButton" && setAnchorEl(null);
  };

  return (
    <>
      {type === "text" ? (
        <Stack direction="row" gap={1} onClick={handleClick}>
          <img
            src={icon}
            width={20}
            alt={iconAlt}
            style={{ cursor: "pointer" }}
          />
          <Typography color="primary.main" fontWeight="bold">
            {text.toUpperCase()}
          </Typography>
        </Stack>
      ) : type === "button" ? (
        <Button variant={variantButton} onClick={handleClick}>
          <img src={icon} width={10} alt={iconAlt} />
          {text}
        </Button>
      ) : type === "validateButton" ? (
        <ValidateButton
          text={text}
          variant={variantButton}
          onClick={
            setAnchorEl &&
            (() => {
              setAnchorEl(null);
              onClick();
            })
          }
        />
      ) : null}
    </>
  );
};

export default BasicMenuItem;
