import { Stack, Typography } from "@mui/material";

import ErrorScreenIcon from "../assets/images/icons/error-screen.png";

import HomeCard from "../components/common/HomeCard";

const Error404 = () => {
  return (
    <HomeCard animation={false} padding={12} width={{ sm: "70%" }}>
      <Stack gap={3}>
        <img
          src={ErrorScreenIcon}
          style={{ margin: "auto" }}
          alt="Icône Erreur 404"
        />
        <Typography variant="h4">
          {"Oups, cette page n'existe pas !"}
        </Typography>
      </Stack>
    </HomeCard>
  );
};

export default Error404;
