import { Typography, Stack, Box } from "@mui/material";

type Props = {
  icon?: string;
  title: string;
  width?: string | number;
  padding?: string | number;
  children: React.ReactNode;
};

const Card = ({
  title,
  children,
  icon,
  width = "30rem",
  padding = 0,
}: Props) => {
  return (
    <Stack
      sx={{
        width: { xs: "90%", md: width },
        borderRadius: "1rem",
        margin: "1rem auto 0.5rem",
        boxShadow:
          "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
      }}
    >
      <Stack
        padding="0.5rem 1rem"
        flexDirection={"row"}
        justifyContent={"center"}
        gap={0.6}
        sx={{ backgroundColor: "primary.main", borderRadius: "1rem 1rem 0 0" }}
      >
        {icon && <img src={icon} alt={title} width={30}></img>}
        <Typography variant="h6" color="white">
          {title}
        </Typography>
      </Stack>
      <Box padding={padding}>{children}</Box>
    </Stack>
  );
};

export default Card;
