import { NavigateFunction, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";

import MCTLogo from "../assets/images/mct-logo.png";

import AuthUserInfo from "../components/AuthUserInfo";

const Header = () => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <Stack
      width="100%"
      height="auto"
      flexDirection="row"
      m={{ sm: "auto", md: "0" }}
      sx={{
        position: "fixed",
        backgroundColor: "white",
        boxShadow:
          "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        zIndex: "100",
      }}
    >
      <Stack
        justifyContent="center"
        p="0.8rem 1rem 0.5rem"
        height="100%"
        width="fit-content"
        onClick={() => navigate("/")}
      >
        <img
          src={MCTLogo}
          width={280}
          alt="Logo Mon Contrôle Technique"
          style={{ cursor: "pointer" }}
        />
      </Stack>
      <AuthUserInfo />
    </Stack>
  );
};

export default Header;
