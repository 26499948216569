import { useState } from "react";
import { Button } from "@mui/material";
import { FieldValues, SubmitHandler } from "react-hook-form";

type Props = {
  text?: string;
  icon?: string;
  iconTitle?: string;
  onClick?: React.MouseEventHandler | SubmitHandler<FieldValues>;
  variant?: "outlined" | "text" | "contained";
  type?: "submit" | "reset" | "button";
  timeout?: number;
};

const ValidateButton = ({
  text,
  icon,
  iconTitle,
  variant = "outlined",
  onClick,
  type = "button",
  timeout = 2000,
}: Props) => {
  const [confirmChoice, setConfirmChoice] = useState<boolean>(false);

  const firstClick = () => {
    setConfirmChoice(true);
    setTimeout(() => {
      setConfirmChoice(false);
    }, timeout);
  };

  return (
    <>
      {!confirmChoice ? (
        <Button
          variant={variant}
          sx={{ width: "fit-content" }}
          title={iconTitle}
          onClick={firstClick}
        >
          {text && <b>{text}</b>}
          {icon && <img src={icon} alt={icon} width={20} />}
        </Button>
      ) : (
        <Button
          type={type}
          variant="contained"
          sx={{
            backgroundColor: "#c01919cc",
            color: "white",
            width: "fit-content",
            "&:hover": {
              backgroundColor: "#c01919cc",
            },
          }}
          onClick={onClick}
        >
          <b>CLIQUEZ POUR CONFIRMER</b>
        </Button>
      )}
    </>
  );
};

export default ValidateButton;
