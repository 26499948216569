import { ReactNode } from "react";
import { Grow, Paper, Stack } from "@mui/material";

import CancelButton from "./CancelIcon";

type Props = {
  children: ReactNode;
  width?: { xs?: string; sm?: string; md?: string; lg?: string; xl?: string };
  padding?: string | number;
  animation?: boolean;
};

const breakpointsWidth = {
  xs: "100%",
  sm: "70%",
  md: "auto",
};

const HomeCard = ({
  children,
  padding = 6,
  width = breakpointsWidth,
  animation = true,
}: Props) => {
  let timeout = 0;
  animation && (timeout = 500);

  return (
    <Stack>
      <Grow in timeout={timeout}>
        <Paper
          elevation={18}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: width,
            margin: `auto`,
            p: { xs: "2rem 0", sm: padding },
            textAlign: "center",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Stack position="absolute" top={15} right={15}>
            <CancelButton />
          </Stack>
          <Stack
            justifyContent="center"
            m={{ xs: "0.5rem 0", sm: "auto" }}
            gap={2}
          >
            {children}
          </Stack>
        </Paper>
      </Grow>
    </Stack>
  );
};

export default HomeCard;
