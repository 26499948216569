import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { AppDispatch } from "../store/store";
import { fetchCenters } from "../store/centers/centers-slice";
import useAuthCheck from "../hooks/useAuthCheck";

import CentersTable from "../components/centers/CentersTable";
import AuthLoading from "../layouts/AuthLoading";
import SuperAdminCheck from "../components/auth/SuperAdminCheck";

const Centers = () => {
  useAuthCheck("centers");
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCenters());
  }, [dispatch]);

  return isAuthenticated && inProgress === "none" ? (
    <SuperAdminCheck>
      <CentersTable />
    </SuperAdminCheck>
  ) : (
    <AuthLoading />
  );
};

export default Centers;
