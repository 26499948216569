import LoadingProgress from "../components/common/LoadingProgress";
import { Stack } from "@mui/material";

const AuthLoading = () => {
  return (
    <Stack>
      <LoadingProgress
        message="Authentification..."
        color="white"
        textVariant="h6"
      />
    </Stack>
  );
};

export default AuthLoading;
