import { ReactNode } from "react";
import { Grow, Modal, Paper, Stack } from "@mui/material";

type Props = {
  open: boolean;
  width?: { xs: string | number; sm: string | number };
  height?: string | number;
  gap?: string | number;
  padding?: string | number;
  textAlign?: string;
  children: ReactNode;
};

const CustomModal = ({
  children,
  open,
  gap = 3,
  padding = 0,
  width = { xs: "20rem", sm: "30rem" },
  height = "15rem",
  textAlign = "center",
}: Props) => {
  return (
    <Modal open={open}>
      <Stack height={"100vh"} justifyContent={"center"} alignItems={"center"}>
        <Grow in={open}>
          <Paper
            sx={{
              width: width,
              height: height,
              textAlign: textAlign,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: gap,
              padding: padding,
            }}
          >
            {children}
          </Paper>
        </Grow>
      </Stack>
    </Modal>
  );
};

export default CustomModal;
