import { useDispatch, useSelector } from "react-redux";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
} from "@mui/material";

import { AppDispatch, RootState } from "../../store/store";
import { updateAdministratorRole } from "../../store/administrators/administrators-slice";
import MenuIcon from "../../assets/images/icons/item-menu.svg";
import UserIcon from "../../assets/images/icons/client.svg";

import LoadingProgress from "../common/LoadingProgress";
import BasicMenu from "../common/BasicMenu";

type AzureUser = {
  displayName: string;
  mail: string;
  externalUserId: string;
  isAdministrator: boolean;
};

const AdministratorsTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const administratorsList: AzureUser[] = useSelector(
    (store: RootState) => store.PROTECH_USERS.administratorsList,
  );
  const isLoading: boolean = useSelector(
    (store: RootState) => store.PROTECH_USERS.loading,
  );

  const handleAdministrator = (externalUserId: string) => {
    dispatch(updateAdministratorRole(externalUserId, false));
  };

  return (
    <>
      <Typography
        variant="h5"
        fontWeight="bold"
        textAlign="center"
        margin="0 auto"
        sx={{ color: "primary.main", width: { xs: "20rem", sm: "auto" } }}
      >
        Gestion des Administrateurs
      </Typography>
      {!isLoading ? (
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {administratorsList.map((user: AzureUser) => (
                <TableRow
                  key={user.externalUserId}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "&:hover": { backgroundColor: "rgba(46,30,76,0.1)" },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <img src={UserIcon} alt="Icône Administrateur" width={30} />
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    <Typography fontWeight={"bold"} color={"primary.main"}>
                      {user.displayName}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{ width: { xs: "10rem", sm: "auto" } }}
                    >
                      {user.mail && (
                        <>
                          {user.mail} <br />
                        </>
                      )}
                      {user.externalUserId}
                    </Typography>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ textAlign: "start" }}
                  >
                    <BasicMenu
                      positionX={0}
                      positionY={45}
                      items={[
                        {
                          type: "validateButton",
                          text: "Retirer les droits",
                          onClick: () =>
                            handleAdministrator(user.externalUserId),
                        },
                      ]}
                    >
                      <img src={MenuIcon} width={20} alt="Icône Menu" />
                    </BasicMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <LoadingProgress />
      )}
    </>
  );
};

export default AdministratorsTable;
