import { Routes, Route } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import Home from "./pages/Home";
import Center from "./pages/Center";
import Error404 from "./layouts/Error404";
import User from "./pages/User";
import Administrators from "./pages/Administrators";
import Organization from "./pages/Organization";
import Centers from "./pages/Centers";
import CTonline from "./pages/CTonline";
import PartnerContainer from "./components/ctonline/PartnerContainer";

function App() {
  return (
    <>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/organization" element={<Organization />} />
        <Route path="/center" element={<Center />} />
        <Route path="/centers" element={<Centers />} />
        <Route path="/user" element={<User />} />
        <Route path="/administrators" element={<Administrators />} />
        <Route path="/ctonline" element={<CTonline />} />
        <Route path="/ctonline/partners" element={<PartnerContainer />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
}

export default App;
