import { useState } from "react";
import {
  Button,
  Checkbox,
  FormGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";

import { CONFIG_API } from "../../data/config.API";
import CarIcon from "../../assets/images/icons/car.svg";
import TruckIcon from "../../assets/images/icons/truck.svg";
import PhotoIcon from "../../assets/images/icons/photo.svg";

import CategoryForm from "../common/CategoryForm";

type InputModel = {
  [key: string]: string;
};

type AgreementsForm = {
  VL: boolean;
  PL: boolean;
};

type Props = {
  setShowCreateForm: (value: boolean) => void;
};

const CenterForm = ({ setShowCreateForm }: Props) => {
  const { register } = useForm<InputModel>();
  const [columnGap] = useState(2);

  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const [showAgreementForm, setShowAgreementForm] = useState<AgreementsForm>({
    PL: false,
    VL: false,
  });

  const handleFile = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedFile(target.files?.[0]);
  };

  return (
    <Stack>
      <Typography variant="h4" fontWeight="bold" color="primary.main" mb={2}>
        Créer un centre
      </Typography>
      <form
        // onSubmit={() => {
        // }}
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <Stack direction="row" gap={20}>
          <CategoryForm title="Informations Générales">
            <Stack direction="row" gap={columnGap}>
              <TextField
                label="Nom du centre"
                size="small"
                type="text"
                {...register("name")}
                required
              />
              <TextField
                label="Code PTS"
                size="small"
                type="text"
                {...register("codePts")}
                required
              />
            </Stack>
            <TextField
              label="Nom du responsable"
              size="small"
              type="text"
              {...register("mainContact")}
              required
              sx={{ width: "14rem" }}
            />
          </CategoryForm>
          <CategoryForm
            borderRadius={2}
            backgroundColor="rgba(70, 47, 87, 0.1)"
            padding="0.5rem 4rem 1rem 1rem "
            title="Activités"
            direction="row"
          >
            <FormGroup>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  icon={
                    <img
                      src={TruckIcon}
                      alt={CONFIG_API.PL}
                      width={40}
                      style={{
                        filter: "grayscale(100%)",
                        transition: "filter 0.5s ease-out",
                      }}
                    />
                  }
                  onClick={() =>
                    setShowAgreementForm((showAgreementForm) => ({
                      ...showAgreementForm,
                      PL: !showAgreementForm.PL,
                    }))
                  }
                  checkedIcon={
                    <img
                      src={TruckIcon}
                      alt={CONFIG_API.PL}
                      width={40}
                      style={{ transition: "filter 0.5s ease-out" }}
                    />
                  }
                  sx={{ width: "fit-content" }}
                  title={CONFIG_API.PL}
                />
                <Checkbox
                  icon={
                    <img
                      src={CarIcon}
                      alt={CONFIG_API.VL}
                      width={40}
                      style={{
                        filter: "grayscale(100%)",
                        paddingTop: "10px",
                        transition: "filter 0.5s ease-out",
                      }}
                    />
                  }
                  onClick={() =>
                    setShowAgreementForm((showAgreementForm) => ({
                      ...showAgreementForm,
                      VL: !showAgreementForm.VL,
                    }))
                  }
                  checkedIcon={
                    <img
                      src={CarIcon}
                      alt={CONFIG_API.VL}
                      width={40}
                      style={{
                        paddingTop: "10px",
                        transition: "filter 0.5s ease-out",
                      }}
                    />
                  }
                  sx={{ width: "fit-content" }}
                  title={CONFIG_API.VL}
                />
              </Stack>
            </FormGroup>
            {showAgreementForm.PL && (
              <TextField
                autoFocus
                label={
                  <>
                    <img src={TruckIcon} alt={CONFIG_API.PL} width={20} />{" "}
                    {"Numéro d'agrément"}
                  </>
                }
                size="small"
                type="text"
                {...register("agreements.")}
              />
            )}
            {showAgreementForm.VL && (
              <TextField
                autoFocus
                label={
                  <>
                    <img src={CarIcon} alt={CONFIG_API.VL} width={20} />
                    {"Numéro d'agrément"}
                  </>
                }
                size="small"
                type="text"
                {...register("agreements.")}
              />
            )}
          </CategoryForm>
        </Stack>

        <Stack direction="row" gap={6}>
          <CategoryForm title="Localisation">
            <Stack gap={columnGap}>
              <TextField
                label="Adresse"
                size="small"
                type="text"
                {...register("address1")}
                required
              />
              <TextField
                label="Complément d'adresse"
                size="small"
                type="text"
                {...register("address2")}
              />
              <Stack direction="row" gap={columnGap}>
                <TextField
                  label="Code postal"
                  size="small"
                  type="text"
                  {...register("postalCode")}
                  required
                />
                <TextField
                  label="Ville"
                  size="small"
                  type="text"
                  {...register("city")}
                  required
                />
              </Stack>
              <Stack direction="row" gap={columnGap}>
                <TextField
                  label="Latitude"
                  size="small"
                  type="number"
                  {...register("latitude")}
                  required
                />
                <TextField
                  label="Longitude"
                  size="small"
                  type="number"
                  {...register("longitude")}
                  required
                />
              </Stack>
            </Stack>
          </CategoryForm>

          <CategoryForm title="Contact">
            <Stack gap={columnGap}>
              <TextField
                label="E-mail"
                size="small"
                type="email"
                {...register("email")}
                required
                sx={{ width: "14rem" }}
              />
              <Stack direction="row" gap={columnGap}>
                <TextField
                  label="Téléphone du centre"
                  size="small"
                  type="tel"
                  {...register("phone")}
                  required
                />
                <TextField
                  label="Téléphone du responsable"
                  size="small"
                  type="tel"
                  {...register("mobile")}
                  required
                />
              </Stack>
              <TextField
                label="Fax"
                size="small"
                type="tel"
                {...register("fax")}
                sx={{ width: "14rem" }}
              />
              <TextField
                label="Site web"
                size="small"
                type="url"
                {...register("website")}
              />
            </Stack>
          </CategoryForm>
        </Stack>

        <CategoryForm title="Informations Complémentaires">
          <TextField
            label="Description du site"
            multiline
            rows={4}
            {...register("description")}
            required
          />
          <input
            accept="image/*"
            id="centerImage"
            type="file"
            onChange={handleFile}
            style={{ display: "none" }}
          />
          <label
            htmlFor="centerImage"
            style={{ width: "fit-content", margin: "auto" }}
          >
            <Button
              variant="outlined"
              aria-label="upload picture"
              component="span"
            >
              <Stack alignItems="center" gap={1} padding={2.5}>
                <img src={PhotoIcon} width={40} alt="Icône Télécharger" />
                <Typography fontWeight="bold" noWrap maxWidth="20rem">
                  {selectedFile ? (
                    <>
                      Fichier :<br /> {selectedFile.name}
                    </>
                  ) : (
                    "Télécharger une image"
                  )}
                </Typography>
              </Stack>
            </Button>
          </label>
        </CategoryForm>
        <Stack direction="row" gap={2} margin="auto">
          <Button variant="contained" type="submit" sx={{ margin: "auto" }}>
            <b>CRÉER LE CENTRE</b>
          </Button>
          <Button
            variant="outlined"
            onClick={() => setShowCreateForm(false)}
            sx={{ margin: "auto" }}
          >
            <b>ANNULER</b>
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default CenterForm;
