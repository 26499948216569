import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";

import {
  deleteExternalUserId,
  getUserFromMail,
  linkAccount,
  setError,
} from "../../store/users/user-slice";
import { AppDispatch, RootState } from "../../store/store";
import { CenterModel } from "../../models/CenterModel";

import ValidateButton from "../common/ValidateButton";
import AzureIcon from "../../assets/images/icons/azure-icon.svg";
import HiddenTextInput from "../common/HiddenTextInput";

type Props = {
  login: string;
  accountType: "User" | "Admin" | "Client";
  externalUserId: string;
  mail: string | undefined;
  userPid: string;
};

const AzureInfo = ({
  login,
  accountType,
  externalUserId,
  mail,
  userPid,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const center: CenterModel = useSelector(
    (store: any) => store.CENTER.currentCenter,
  );
  const error: string | null = useSelector(
    (store: RootState) => store.USER.error,
  );
  const isLoading: boolean = useSelector(
    (store: RootState) => store.USER.loading,
  );
  const [currentUserInfo, setCurrentUserInfo] = useState({
    externalUserId: externalUserId,
    mail: mail,
  });

  const linkAzureAccount = async (userMail: string) => {
    const user = await dispatch(getUserFromMail(userMail));
    if (user) {
      await dispatch(
        linkAccount(center.organizationPid, accountType, userPid, user.id),
      );
      setCurrentUserInfo({ externalUserId: user.id, mail: userMail });
    }
  };

  const unlinkAzureAccount = () => {
    if (center) {
      dispatch(
        deleteExternalUserId(
          center.organizationPid,
          accountType,
          login,
          externalUserId,
        ),
      );
      setCurrentUserInfo({ externalUserId: "", mail: "" });
    }
  };

  return (
    <Stack alignItems="center" mt="1rem">
      <Box
        sx={{
          transition: "filter 0.5s ease-in-out",
          filter: !currentUserInfo.externalUserId ? "grayscale(100%)" : null,
        }}
      >
        <img src={AzureIcon} width={50} alt="Icône Azure" />
      </Box>
      {currentUserInfo.externalUserId ? (
        <>
          <Typography variant="subtitle2" marginBottom="3px">
            <b>Identifiant Azure :</b>
            <br />
            {!isLoading && (
              <>
                {mail}
                <br />
              </>
            )}
            {currentUserInfo.externalUserId}
          </Typography>
          <ValidateButton
            text="Délier le compte"
            onClick={unlinkAzureAccount}
          />
        </>
      ) : (
        <>
          <Typography variant="subtitle2" marginBottom="6px">
            {"Ce compte n'est pas relié à Azure."}
          </Typography>
          <HiddenTextInput
            inputType="email"
            inputName="mail"
            buttonText="Lier le compte"
            label="E-mail de l'utilisateur"
            setError={() => dispatch(setError(null))}
            errorMessage={error}
            successMessage="L'utilisateur a bien été relié."
            buttonStyle={{ width: "fit-content" }}
            onSubmitFunction={linkAzureAccount}
          />
        </>
      )}
    </Stack>
  );
};

export default AzureInfo;
