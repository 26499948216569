import { Stack, Typography } from "@mui/material";

type Props = {
  title: string;
  children: React.ReactNode;
  direction?: string;
  border?: string;
  backgroundColor?: string;
  borderRadius?: number | string;
  padding?: string | number;
  justifySelf?: string | number;
};

const CategoryForm = ({
  title,
  children,
  padding = "none",
  border = "none",
  justifySelf = "initial",
  backgroundColor = "initial",
  borderRadius = "none",
  direction = "column",
}: Props) => {
  return (
    <Stack
      gap={1.5}
      marginBottom="1rem"
      sx={{
        direction: direction,
        justifySelf: justifySelf,
        border: border,
        padding: padding,
        backgroundColor: backgroundColor,
        borderRadius: borderRadius,
      }}
    >
      <Typography fontWeight="bold" textAlign="left">
        {title}
      </Typography>
      {children}
    </Stack>
  );
};

export default CategoryForm;
