import AdministratorsTable from "./AdministratorsTable";
import AddAdministrator from "./AddAdministrator";
import { Stack } from "@mui/material";

const AdministratorsInfo = () => {
  return (
    <Stack height={{ xs: "40rem", xl: "45rem" }} gap={"2rem"}>
      <AdministratorsTable />
      <AddAdministrator />
    </Stack>
  );
};

export default AdministratorsInfo;
