import { configureStore } from "@reduxjs/toolkit";
import { centersSlice } from "./centers/centers-slice";
import { centerSlice } from "./centers/center-slice";
import { userSlice } from "./users/user-slice";
import { organizationSlice } from "./organizations/organization-slice";
import { administratorsSlice } from "./administrators/administrators-slice";
import { accountSlice } from "./account/account-slice";
import { alertSlice } from "./layout/alert-slice";

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const store = configureStore({
  reducer: {
    CENTERS: centersSlice.reducer,
    CENTER: centerSlice.reducer,
    USER: userSlice.reducer,
    ORGANIZATION: organizationSlice.reducer,
    PROTECH_USERS: administratorsSlice.reducer,
    ACCOUNT: accountSlice.reducer,
    ALERT: alertSlice.reducer,
  },
});

export { store };
