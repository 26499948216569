import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Grid, Stack } from "@mui/material";

import { AppDispatch, RootState } from "../store/store";
import {
  getCenterAdministrator,
  getCenterUsers,
  setCurrentCenter,
} from "../store/centers/center-slice";
import { setCurrentOrganization } from "../store/organizations/organization-slice";
import HousesIcon from "../assets/images/icons/houses.svg";
import AdminitratorsIcon from "../assets/images/icons/administrator-settings.svg";
import CTonlineIcon from "../assets/images/icons/ctonline-white.png";

import Tile from "../components/common/Tile";
import useAuthCheck from "../hooks/useAuthCheck";
import AuthLoading from "../layouts/AuthLoading";
import SuperAdminCheck from "../components/auth/SuperAdminCheck";

const Home = () => {
  useAuthCheck();
  const isAuthenticated: boolean = useIsAuthenticated();
  const { inProgress } = useMsal();

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const currentAccount = useSelector(
    (store: RootState) => store.ACCOUNT.currentAccount,
  );

  useEffect(() => {
    dispatch(setCurrentCenter(null));
    dispatch(getCenterUsers([]));
    dispatch(getCenterAdministrator([]));
    dispatch(setCurrentOrganization(null));
  }, [dispatch]);

  return (
    <Stack sx={{ border: "none", margin: "0 auto" }}>
      {isAuthenticated && inProgress === "none" ? (
        <SuperAdminCheck>
          {currentAccount.isAdministrator !== undefined && (
            <Grid margin="1rem auto" width={"fit-content"} container gap={6}>
              <Tile
                title="Liste des centres"
                icon={HousesIcon}
                onClick={() => navigate("/centers")}
              />
              <Tile
                title="Gestion des administrateurs"
                icon={AdminitratorsIcon}
                onClick={() => navigate("/administrators")}
              />
              <Tile
                title="Paramètres CTonline"
                icon={CTonlineIcon}
                onClick={() => navigate("/ctonline")}
              />
            </Grid>
          )}
        </SuperAdminCheck>
      ) : (
        <AuthLoading />
      )}
    </Stack>
  );
};

export default Home;
