import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { AxiosResponse } from "axios";

import { AppDispatch } from "../../store/store";
import { CONFIG_API } from "../../data/config.API";
import { CenterModel } from "../../models/CenterModel";
import { APIResponseModel } from "../../models/ApiResponseList";
import { api } from "../../utils/api";
import { setAlert } from "../../store/layout/alert-slice";

import CustomModal from "../../layouts/CustomModal";
import { setCurrentCenter } from "../../store/centers/center-slice";

interface Props {
  data: CenterModel;
}

const AccessInfo = ({ data }: Props) => {
  const dispatch: AppDispatch = useDispatch();

  const [accessData, setAccessData] = useState<{
    isFoxChecker: boolean;
    hasEarlyAccessVersion: boolean;
  }>({
    isFoxChecker: data.isFoxChecker,
    hasEarlyAccessVersion: data.hasEarlyAccessVersion,
  });
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);

  const updateFoxCheckerStatus = async (value: boolean) => {
    setUpdateLoading(true);
    try {
      const response: AxiosResponse<APIResponseModel<boolean>> = await api.put(
        `${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.ORGANIZATION}/${data.organizationPid}/IsFoxChecker`,
        {
          isFoxChecker: value,
        },
      );
      if (response.data.success) {
        setAccessData({
          ...accessData,
          isFoxChecker: value,
          hasEarlyAccessVersion: value
            ? accessData.hasEarlyAccessVersion
            : false,
        });
        dispatch(
          setCurrentCenter({
            ...data,
            isFoxChecker: value,
            hasEarlyAccessVersion: value
              ? accessData.hasEarlyAccessVersion
              : false,
          }),
        );
        const message = value
          ? `${data.organizationCode} est désormais Fox Checker ! 🦊`
          : `${data.organizationCode} n'est plus Fox Checker`;
        dispatch(setAlert({ id: "global-alert", type: "success", message }));
      } else {
        setAccessData({ ...accessData, isFoxChecker: !value });
        dispatch(
          setAlert({
            id: "global-alert",
            type: "error",
            message: response.data.messages[0].message,
          }),
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          id: "global-alert",
          type: "error",
          message: `Une erreur est survenue.`,
        }),
      );
      setAccessData({ ...accessData, isFoxChecker: !value });
    } finally {
      setUpdateLoading(false);
    }
  };

  const updateEarlyAccessStatus = async (value: boolean) => {
    setUpdateLoading(true);
    try {
      const response: AxiosResponse<APIResponseModel<boolean>> = await api.put(
        `${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.ORGANIZATION}/${data.organizationPid}/HasEarlyAccess`,
        {
          hasEarlyAccess: value,
        },
      );
      if (response.data.success) {
        setAccessData({ ...accessData, hasEarlyAccessVersion: value });
        dispatch(setCurrentCenter({ ...data, hasEarlyAccessVersion: value }));
        const message = value
          ? `Early Access activé pour ${data.organizationCode}.`
          : `Early Access retiré pour ${data.organizationCode}.`;
        dispatch(setAlert({ id: "global-alert", type: "success", message }));
      } else {
        setAccessData({ ...accessData, hasEarlyAccessVersion: false });
        dispatch(
          setAlert({
            id: "global-alert",
            type: "error",
            message: response.data.messages[0].message,
          }),
        );
      }
    } catch (error) {
      setAccessData({ ...accessData, hasEarlyAccessVersion: false });
      dispatch(
        setAlert({
          id: "global-alert",
          type: "error",
          message: `Une erreur est survenue.`,
        }),
      );
    } finally {
      setUpdateLoading(false);
    }
  };

  return (
    <>
      <CustomModal
        open={showConfirmationModal}
        padding={"2rem"}
        width={{ xs: "35rem", sm: "45rem" }}
      >
        <Stack margin="auto" gap={4}>
          <Alert severity="warning">
            {`Cette action retirera le statut de Fox Checker ${
              accessData.hasEarlyAccessVersion
                ? "et désactivera l'accès à la version Early Access"
                : ""
            } pour tous les centres de ${data.organizationCode}.`}
          </Alert>
          <Stack flexDirection="row" gap={1} justifyContent={"Center"}>
            <Button
              variant="contained"
              onClick={() => {
                updateFoxCheckerStatus(false);
                setShowConfirmationModal(false);
              }}
            >
              Confirmer
            </Button>
            <Button
              variant="outlined"
              onClick={() => setShowConfirmationModal(false)}
            >
              Annuler
            </Button>
          </Stack>
        </Stack>
      </CustomModal>
      <FormControlLabel
        label="Fox Checker"
        disabled={updateLoading}
        control={
          <Checkbox
            checked={accessData.isFoxChecker}
            onChange={(e) => {
              if (!e.target.checked) {
                setAccessData({ ...accessData, isFoxChecker: true });
                setShowConfirmationModal(true);
              } else {
                updateFoxCheckerStatus(e.target.checked);
              }
            }}
          />
        }
      />
      <FormControlLabel
        label="Early Access"
        disabled={updateLoading || !accessData.isFoxChecker}
        control={
          <Checkbox
            checked={accessData.hasEarlyAccessVersion}
            onChange={(e) => updateEarlyAccessStatus(e.target.checked)}
          />
        }
      />
    </>
  );
};

export default AccessInfo;
