import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert, Grow, Stack } from "@mui/material";

import { AppDispatch, RootState } from "../store/store";
import { AlertModel, clearAlert } from "../store/layout/alert-slice";

interface Props {
  id: string;
  width?: string;
  margin?: string;
  isCenteredOverlay?: boolean;
}

const AlertMessage = ({
  id,
  width = "content-fit",
  margin = "auto",
  isCenteredOverlay = false,
}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const alertState = useSelector((store: RootState) =>
    store.ALERT.alerts.filter((alert) => alert.id === id),
  );

  const [visibleAlert, setVisibleAlert] = useState(false);
  const [currentAlert, setCurrentAlert] = useState<null | AlertModel>(null);

  const alertStyle = isCenteredOverlay
    ? {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "9999",
      }
    : {};

  useEffect(() => {
    if (alertState.length > 0) {
      if (currentAlert?.id !== alertState[0]?.id) {
        const firstAlert = alertState[0];
        setCurrentAlert(firstAlert); // Set the first alert as current alert
        setVisibleAlert(true);
      }

      const timeout = setTimeout(() => {
        setVisibleAlert(false);
        dispatch(clearAlert(id));
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [alertState, dispatch, id]);

  return currentAlert ? (
    <Stack width={width} margin={margin} sx={alertStyle}>
      <Grow in={visibleAlert} onExited={() => setCurrentAlert(null)}>
        <Alert severity={currentAlert.type} sx={{ fontWeight: "bold" }}>
          {currentAlert.message}
        </Alert>
      </Grow>
    </Stack>
  ) : null;
};
export default AlertMessage;
