import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { AppDispatch } from "../store/store";
import { fetchUsers } from "../store/administrators/administrators-slice";
import useAuthCheck from "../hooks/useAuthCheck";

import HomeCard from "../components/common/HomeCard";
import AdministratorsInfo from "../components/administrators/AdministratorsInfo";
import AuthLoading from "../layouts/AuthLoading";
import SuperAdminCheck from "../components/auth/SuperAdminCheck";

const Administrators = () => {
  useAuthCheck("administrators");
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  return isAuthenticated && inProgress === "none" ? (
    <SuperAdminCheck>
      <HomeCard width={{ xs: "95%", sm: "fit-content" }} padding="2rem 6rem">
        <AdministratorsInfo />
      </HomeCard>
    </SuperAdminCheck>
  ) : (
    <AuthLoading />
  );
};

export default Administrators;
