import { Stack } from "@mui/material";
import CarIcon from "../../assets/images/icons/car.svg";
import TruckIcon from "../../assets/images/icons/truck.svg";
import MotoIcon from "../../assets/images/icons/moto.svg";

// Defines a type for the keys
type ActivityKey = "VL" | "PL" | "MOT";

// Mapping values into icons
const ACTIVITY_ICONS: Record<ActivityKey, string> = {
  VL: CarIcon,
  PL: TruckIcon,
  MOT: MotoIcon,
};

type Props = {
  activities: string[];
  width: string | number;
  justifyContent?: string;
};

const ActivitiesIcons = ({
  activities,
  width,
  justifyContent = "start",
}: Props) => {
  // Generate icons for each activity
  const icons = activities.map((activity) => {
    const key: ActivityKey = activity as ActivityKey;
    return (
      <img
        key={activity}
        src={ACTIVITY_ICONS[key]}
        alt={activity}
        width={width}
        title={activity}
        style={activity === "VL" ? { paddingTop: "4px" } : {}}
      />
    );
  });

  return (
    <Stack direction={"row"} justifyContent={justifyContent} gap={0.5}>
      {icons}
    </Stack>
  );
};

export default ActivitiesIcons;
