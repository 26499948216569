import { useCallback, useEffect } from "react";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { Modal, Paper, Stack, Typography } from "@mui/material";

import { checkIsSuperAdministrator } from "../../store/account/account-slice";

import LogoutButton from "../common/LogoutButton";
import ErrorUnauthorized from "../../assets/images/icons/unauthorized.png";
import MCTHeightLogo from "../../assets/images/mct-height-logo.png";

type Props = {
  children: React.ReactNode;
};

const SuperAdminCheck = ({ children }: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const { inProgress, accounts } = useMsal();

  const currentAccount = useSelector(
    (store: RootState) => store.ACCOUNT.currentAccount,
  );

  const checkAccountIsSuperAdministrator = useCallback(async () => {
    dispatch(checkIsSuperAdministrator(accounts[0].idTokenClaims?.sub));
  }, [accounts, dispatch]);

  useEffect(() => {
    if (inProgress === "none" && accounts[0]) {
      checkAccountIsSuperAdministrator();
    }
  }, [inProgress, accounts, checkAccountIsSuperAdministrator]);

  return (
    <>
      {currentAccount.isAdministrator !== undefined &&
        (currentAccount.isAdministrator === true ? (
          children
        ) : (
          <Modal open={true}>
            <Stack
              height={"100vh"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Paper
                sx={{
                  width: { xs: "20rem", sm: "30rem" },
                  height: "fit-content",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 3,
                  padding: "0 0 1rem",
                }}
              >
                <img
                  src={MCTHeightLogo}
                  width="65%"
                  alt="Logo Mon Contrôle Technique"
                />

                <img
                  src={ErrorUnauthorized}
                  style={{ margin: "auto" }}
                  width={80}
                  alt={"Accès non autorisé"}
                />
                <Typography fontWeight="bold" sx={{ color: "primary.main" }}>
                  {"Vous n'êtes pas autorisé à accéder à l'administration."}
                </Typography>
                <LogoutButton width={"auto"} />
              </Paper>
            </Stack>
          </Modal>
        ))}
    </>
  );
};

export default SuperAdminCheck;
