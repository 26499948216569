import { createSlice } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";

// SLICE
export const organizationSlice = createSlice({
  name: "organizationSlice",
  initialState: {
    currentOrganization: {
      centersList: [],
    },
    loading: true,
    error: null,
  },
  reducers: {
    setCurrentOrganization: (currentSlice, action) => {
      currentSlice.currentOrganization.centersList = action.payload;
    },
    setError: (currentSlice, action) => {
      currentSlice.error = action.payload;
    },
    setLoading: (currentSlice, action) => {
      currentSlice.loading = action.payload;
    },
  },
});

export const { setCurrentOrganization, setLoading, setError } =
  organizationSlice.actions;

// Get organization's centers
export const fetchCenters = (organizationPid) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setError(null));
  try {
    if (organizationPid !== null) {
      await api
        .get(
          `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}`,
        )
        .then((response) => {
          dispatch(setLoading(false));
          dispatch(setCurrentOrganization(response.data.items));
        });
    } else {
      dispatch(setLoading(false));
      dispatch(setError("Il manque le PID de l'organisation"));
    }
  } catch (err) {
    return console.error(err.message);
  }
};
