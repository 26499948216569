import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { fetchCenters } from "../store/organizations/organization-slice";
import { AppDispatch } from "../store/store";

import HomeCard from "../components/common/HomeCard";
import OrganizationInfo from "../components/organization/OrganizationInfo";
import CenterForm from "../components/organization/CenterForm";

const Organization = () => {
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);

  useEffect(() => {
    location.state && dispatch(fetchCenters(location.state.organizationPid));
  }, [location.state, dispatch]);

  const currentOrganization = useSelector(
    (store: any) => store.ORGANIZATION.currentOrganization,
  );

  return (
    <>
      {location.state ? (
        <HomeCard>
          {!showCreateForm ? (
            <OrganizationInfo
              data={currentOrganization}
              organizationCode={location.state.organizationCode}
              // setShowCreateForm={setShowCreateForm}
            />
          ) : (
            <CenterForm setShowCreateForm={setShowCreateForm} />
          )}
        </HomeCard>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default Organization;
