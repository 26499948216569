import { Grid, Stack, Typography } from "@mui/material";

type Props = {
  title: string;
  icon: string;
  width?: string | number;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

const Tile = ({ icon, title, onClick, width = 100 }: Props) => {
  return (
    <Grid item margin={"auto"} onClick={onClick}>
      <Stack
        width="15rem"
        height="15rem"
        bgcolor={"rgba(70, 47, 87, 0.7)"}
        border="1px solid rgba(70, 47, 87, 0.7)"
        padding={2}
        gap={1}
        borderRadius={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent="center"
        sx={{
          transition: "all 0.5s ease",
          cursor: "pointer",
          "&:hover": { bgcolor: "rgba(70, 47, 87, 1)", borderColor: "white" },
        }}
      >
        <img src={icon} alt={title} width={width} />
        <Typography fontSize={20} color="white" textAlign={"center"}>
          {title}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default Tile;
