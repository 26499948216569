import { useState } from "react";
import { Divider, Typography } from "@mui/material";

type Props = {
  title: string;
  children: React.ReactNode;
  isLastItem?: boolean;
  data: string[];
};

const InfoItem = ({ title, children, data, isLastItem }: Props) => {
  // FALSE if one of data element isn't defined
  const [isDataCompleted] = useState<boolean>(data.some((element) => element));

  return (
    <>
      <Typography fontWeight="bold">{title} :</Typography>
      {isDataCompleted ? (
        children
      ) : (
        <Typography fontWeight="bold" color="red">
          Information incomplète
        </Typography>
      )}
      {!isLastItem && <Divider sx={{ margin: "0.5rem" }} />}
    </>
  );
};

export default InfoItem;
