import { createTheme } from "@mui/material";

const customTheme = createTheme({
  palette: {
    primary: { main: "#ea6927", light: "#e8e3eb" },
    secondary: { main: "#371F4B" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default customTheme;
