import { createSlice } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";

// SLICE
export const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    currentUser: {
      CTonlineInfo: null,
      AzureInfo: null,
    },
    loading: true,
    error: null,
  },
  reducers: {
    setCurrentUser: (currentSlice, action) => {
      currentSlice.currentUser.CTonlineInfo = action.payload;
    },
    linkUserToAzure: (currentSlice, payload) => {
      currentSlice.currentUser.CTonlineInfo.externalUserId = payload;
    },
    unlinkUserFromAzure: (currentSlice) => {
      currentSlice.currentUser.CTonlineInfo.externalUserId = null;
    },
    setAzureInformation: (currentSlice, action) => {
      currentSlice.currentUser.AzureInfo = action.payload;
    },
    setLoading: (currentSlice, action) => {
      currentSlice.loading = action.payload;
    },
    setError: (currentSlice, action) => {
      currentSlice.error = action.payload;
    },
  },
});

export const {
  unlinkUserFromAzure,
  setCurrentUser,
  setAzureInformation,
  setLoading,
  setError,
} = userSlice.actions;

export const deleteExternalUserId =
  (organizationPid, accountType, login, externalUserId) => async (dispatch) => {
    dispatch(setError(null));
    try {
      await api
        .put(
          `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.EXTERNAL_ACCOUNT}`,
          { login: login, externalUserId: externalUserId },
        )
        .then((response) =>
          dispatch(unlinkUserFromAzure(response.success, login)),
        );
    } catch (err) {
      dispatch(setError(err.message));
    }
  };

export const updateUserPassword =
  (organizationPid, accountType, login, password) => async (dispatch) => {
    dispatch(setError(null));
    try {
      await api.put(
        `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.LOGIN}/${login}/${CONFIG_API.PASSWORD}`,
        { password: password },
      );
    } catch (err) {
      dispatch(setError(err.message));
    }
  };

export const getUser = (externalUserId) => async (dispatch) => {
  dispatch(setError(null));
  try {
    dispatch(setLoading(true));
    if (externalUserId) {
      await api
        .get(
          `/${CONFIG_API.CTONLINE_CONNECT}/${CONFIG_API.USER}/${externalUserId}`,
        )
        .then((response) => {
          dispatch(setAzureInformation(response.data.item));
          dispatch(setLoading(false));
        });
    }
  } catch (err) {
    dispatch(setError(err.message));
  }
};

export const linkAccount =
  (organizationPid, accountType, internalUserId, externalUserId) =>
  async (dispatch) => {
    try {
      const response = await api.put(
        `${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.REGISTRER_ACCOUNT}`,
        { internalUserPid: internalUserId, externalUserId: externalUserId },
      );
      if (!response.data.success) {
        dispatch(setError("Une erreur est survenue lors de la liaison."));
      }
    } catch (error) {
      dispatch(setError(error.message));
    }
  };

export const getUserFromMail = (mail) => async (dispatch) => {
  try {
    const response = await api.get(
      `${CONFIG_API.CTONLINE_CONNECT}/${CONFIG_API.USER}/${CONFIG_API.MAIL}/${mail}`,
    );
    if (response.data.item) {
      return response.data.item;
    } else {
      dispatch(
        setError("Cet utilisateur n'existe pas dans la base de données."),
      );
      return false;
    }
  } catch (error) {
    dispatch(setError(error.message));
  }
};
