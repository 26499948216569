import { List, Typography } from "@mui/material";

import LoadingProgress from "./LoadingProgress";
import { CenterModel } from "../../models/CenterModel";
import { UserModel } from "../../models/UserModel";

type Props = {
  list: CenterModel[] | UserModel[];
  children: React.ReactNode;
  noneText?: string;
  isLoading: boolean;
  error?: null | string;
};

const ListCard = ({
  list,
  children,
  isLoading,
  error = null,
  noneText = "Rien à afficher",
}: Props) => {
  return (
    <List dense={true} sx={{ pt: 0 }}>
      {isLoading ? (
        <LoadingProgress hideMessage size={"1.5rem"} />
      ) : error === null ? (
        list && list.length > 0 ? (
          <>{children}</>
        ) : (
          <Typography sx={{ padding: "0.5rem" }}>{noneText}</Typography>
        )
      ) : (
        <Typography color="red" fontWeight="bold" p={2}>
          {error}
        </Typography>
      )}
    </List>
  );
};

export default ListCard;
