import { Stack, Typography, CircularProgress } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";

type Props = {
  message?: string;
  color?: string;
  textVariant?: Variant;
  size?: string | number;
  margin?: string | number;
  hideMessage?: boolean;
};

const LoadingProgress = ({
  message = "Chargement en cours...",
  margin = "auto",
  size = 40,
  color = "primary.main",
  textVariant = undefined,
  hideMessage = false,
}: Props) => {
  return (
    <Stack justifyContent="center" alignItems="center" gap={2} m={2}>
      <CircularProgress size={size} sx={{ margin: margin, color: color }} />
      {message && !hideMessage && (
        <Typography
          variant={textVariant}
          fontWeight="bold"
          sx={{ color: color }}
        >
          {message}
        </Typography>
      )}
    </Stack>
  );
};

export default LoadingProgress;
