import { createSlice } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";

// SLICE
export const centerSlice = createSlice({
  name: "centerSlice",
  initialState: {
    currentCenter: null,
    usersList: [],
    administratorsList: [],
    loading: true,
    error: null,
  },
  reducers: {
    setCurrentCenter: (currentSlice, action) => {
      currentSlice.currentCenter = action.payload;
    },
    getCenterUsers: (currentSlice, action) => {
      currentSlice.usersList = action.payload;
      currentSlice?.usersList.map(
        (user) => (user.accountType = CONFIG_API.USER),
      );
    },
    getCenterAdministrator: (currentSlice, action) => {
      currentSlice.administratorsList = action.payload;
      currentSlice?.administratorsList.map(
        (admin) => (admin.accountType = CONFIG_API.ADMIN),
      );
    },
    setError: (currentSlice, action) => {
      currentSlice.error = action.payload;
    },
    setLoading: (currentSlice, action) => {
      currentSlice.loading = action.payload;
    },
  },
});

export const {
  setCurrentCenter,
  getCenterUsers,
  getCenterAdministrator,
  unlinkUserFromAzure,
  setLoading,
  setError,
} = centerSlice.actions;

export const fetchCenterData = (centerPid) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await api
      .get(
        `${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.CENTER}/${centerPid}`,
      )
      .then((response) => {
        if (response.data.success) {
          dispatch(setCurrentCenter(response.data.item));
        } else {
          setError(response.data.errors[0].message);
        }
      });
  } catch (error) {
    return console.error(error.message);
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchUsers =
  (organizationPid, pid, accountType) => async (dispatch) => {
    dispatch(setLoading(true));
    dispatch(setError(null));
    try {
      if (organizationPid !== null) {
        await api
          .get(
            `/${CONFIG_API.CTONLINE}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}/${pid}/${CONFIG_API.ACCOUNT}/${accountType}/${CONFIG_API.EXTERNAL_ACCOUNT}`,
          )
          .then((response) => {
            dispatch(setLoading(false));
            if (accountType === CONFIG_API.USER) {
              dispatch(getCenterUsers(response.data.items));
            } else if (accountType === CONFIG_API.ADMIN) {
              dispatch(getCenterAdministrator(response.data.items));
            }
          });
      } else {
        dispatch(setLoading(false));
        dispatch(setError("Il manque le PID de l'organisation"));
      }
    } catch (err) {
      return console.error(err.message);
    }
  };
