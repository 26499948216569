import { ReactNode } from "react";
import { Stack } from "@mui/material";
import { MsalProvider } from "@azure/msal-react";

import Header from "./Header";
import AlertMessage from "./AlertMessage";

type Props = {
  children: ReactNode;
  msalInstance: any;
};

const Layout = ({ children, msalInstance }: Props) => {
  return (
    <MsalProvider instance={msalInstance}>
      <Header />
      <Stack
        paddingTop="6rem"
        paddingBottom="2rem"
        margin="auto"
        sx={{ backgroundColor: "primary.main", minHeight: "100vh" }}
      >
        <AlertMessage id="global-alert" isCenteredOverlay />
        {children}
      </Stack>
    </MsalProvider>
  );
};

export default Layout;
