import { useCallback, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import { Alert, Grid, Grow, Slide, Stack, Typography } from "@mui/material";

import { api } from "../../utils/api/index";
import { APIResponsesModel } from "../../models/ApiResponseList";
import { PartnerModel } from "./PartnerCard";
import { CONFIG_API } from "../../data/config.API";

import LoadingProgress from "../common/LoadingProgress";
import HomeCard from "../common/HomeCard";
import PartnerCard from "./PartnerCard";

const PartnerContainer = () => {
  const [partnersList, setPartnersList] = useState<undefined | PartnerModel[]>(
    undefined,
  );
  const [error, setError] = useState<string>("");
  const [message, setMessage] = useState<{
    type: "success" | "error";
    text: string;
  }>({ type: "success", text: "" });

  const fetchAllPartners = useCallback(async () => {
    try {
      const response: AxiosResponse<APIResponsesModel<PartnerModel>> =
        await api.get(
          `${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.PARTNER}`,
        );
      if (response.data.success) {
        setPartnersList(response.data.items);
      }
    } catch (error) {
      setError("Impossible de récupérer la liste des partenaires.");
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchAllPartners();
    })();
  }, [fetchAllPartners]);

  return (
    <>
      {!error ? (
        partnersList ? (
          <Stack margin="auto">
            {message.text && (
              <Slide in timeout={200}>
                <Stack margin="0 auto 2rem" width="auto">
                  <Alert severity={message.type}>{message.text}</Alert>
                </Stack>
              </Slide>
            )}
            <Grid
              container
              gap={"2rem"}
              alignContent={"center"}
              justifyContent={"center"}
              padding="0 10rem"
            >
              {partnersList.map((partner: PartnerModel) => (
                <Grow in timeout={500} key={partner.pid}>
                  <Grid item>
                    <PartnerCard
                      pid={partner.pid}
                      name={partner.name}
                      defaultColorHsl={partner.defaultColorHsl}
                      setMessage={setMessage}
                      fetchAllPartners={fetchAllPartners}
                    />
                  </Grid>
                </Grow>
              ))}
            </Grid>
          </Stack>
        ) : (
          <HomeCard>
            <LoadingProgress message="Chargement des partenaires" />
          </HomeCard>
        )
      ) : (
        <HomeCard>
          <Stack gap={3}>
            <Typography color="primary.main" fontWeight="bold">
              {error}
            </Typography>
          </Stack>
        </HomeCard>
      )}
    </>
  );
};

export default PartnerContainer;
