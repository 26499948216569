import { NavigateFunction, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import { AppDispatch, RootState } from "../../store/store";
import { UserModel } from "../../models/UserModel";
import { CONFIG_API } from "../../data/config.API";
import { setCurrentUser } from "../../store/users/user-slice";
import UsersIcon from "../../assets/images/icons/users.svg";
import UserIcon from "../../assets/images/icons/client.svg";
import AzureIcon from "../../assets/images/icons/azure-icon.svg";
import AdministratorIcon from "../../assets/images/icons/administrator.svg";

import ListCard from "../common/ListCard";
import Card from "../common/Card";

const UsersList = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const error: null | string = useSelector(
    (store: RootState) => store.CENTER.error,
  );
  const loading: boolean = useSelector(
    (store: RootState) => store.CENTER.loading,
  );
  const usersList: UserModel[] = useSelector(
    (store: RootState) => store.CENTER.usersList,
  );
  const administratorsList: UserModel[] = useSelector(
    (store: RootState) => store.CENTER.administratorsList,
  );
  let allCenterUsers: UserModel[] = [];

  if (usersList !== null && administratorsList !== null) {
    allCenterUsers = administratorsList.concat(usersList);
  }

  const navigateToUser = (user: UserModel) => {
    dispatch(setCurrentUser(user));
    navigate("/user");
  };

  return (
    <Card
      icon={UsersIcon}
      title="Utilisateurs du centre"
      padding="0.5rem 0 0"
      width="21rem"
    >
      <ListCard
        list={allCenterUsers}
        error={error}
        isLoading={loading}
        noneText="Aucun utilisateur à afficher"
      >
        {allCenterUsers &&
          allCenterUsers.map((user: UserModel) => (
            <ListItem
              key={user.login}
              onClick={() => {
                navigateToUser(user);
              }}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "primary.main",
                  backgroundColor: "rgba(46,30,76,0.1)",
                },
              }}
            >
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <ListItemIcon sx={{ minWidth: "2rem" }}>
                  <img
                    src={
                      user.accountType === CONFIG_API.ADMIN
                        ? AdministratorIcon
                        : UserIcon
                    }
                    width={30}
                    title={user.accountType}
                    alt={user.accountType}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Stack direction="row" gap={0.5}>
                    <Typography width={250}>
                      {user.firstName} {user.lastName}
                    </Typography>
                    <Stack
                      justifyContent={"center"}
                      sx={{
                        filter: !user.externalUserId ? "grayscale(100%)" : null,
                      }}
                    >
                      <img
                        src={AzureIcon}
                        width={20}
                        alt="Icône Azure"
                        title={
                          user.externalUserId
                            ? "Compte relié à Azure"
                            : "Ce compte n'est pas relié à Azure"
                        }
                      />
                    </Stack>
                  </Stack>
                </ListItemText>
              </Stack>
            </ListItem>
          ))}
      </ListCard>
    </Card>
  );
};

export default UsersList;
