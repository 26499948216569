import { useState } from "react";
import {
  Alert,
  Button,
  Fade,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { CSSProperties } from "@mui/material/styles/createTypography";

import CloseIcon from "../../assets/images/icons/cross.svg";

type Input = {
  [key: string]: string;
};

type Props = {
  inputType?: string;
  inputName: string;
  label: string;
  successMessage: string;
  errorMessage: string | null;
  setError: (value: string | null) => void;
  onSubmitFunction: (userMail: string) => Promise<void> | void;
  buttonText: string;
  buttonStyle: CSSProperties;
  variantButton?: "outlined" | "contained" | "text";
};

const HiddenTextInput = ({
  inputName,
  errorMessage,
  successMessage,
  setError,
  label,
  buttonText,
  buttonStyle,
  onSubmitFunction,
  inputType = "text",
  variantButton = "outlined",
}: Props) => {
  const { register, handleSubmit, reset } = useForm<Input>();
  const [showInput, setShowInput] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [fadeAnimation, setFadeAnimation] = useState<boolean>(true);

  const onSubmit: SubmitHandler<Input> = (formData) => {
    if (formData) {
      reset();
      onSubmitFunction(formData[inputName]);
      setShowInput(false);
      setShowMessage(true);
      setTimeout(() => {
        setFadeAnimation(false);
      }, 2500);
      setTimeout(() => {
        setShowMessage(false);
        setError(null);
        setFadeAnimation(true);
      }, 2900);
    }
  };

  return (
    <>
      {showMessage && (
        <Fade in={fadeAnimation} timeout={1000}>
          {!errorMessage ? (
            <Alert severity="success">{successMessage}</Alert>
          ) : (
            <Alert severity="error">{errorMessage}</Alert>
          )}
        </Fade>
      )}
      {!showInput ? (
        <Button
          variant={variantButton}
          sx={buttonStyle}
          onClick={() => {
            setShowInput(true);
            setShowMessage(false);
          }}
        >
          <b>{buttonText}</b>
        </Button>
      ) : (
        <Stack gap={1} component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register(inputName)}
            required
            autoFocus
            type={inputType}
            size="small"
            label={label}
            sx={{ width: "fit-content", m: "auto" }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={() => {
                    setShowInput(false);
                    reset();
                  }}
                  sx={{ cursor: "pointer" }}
                >
                  <img src={CloseIcon} width={15} alt="Icône Annuler" />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="outlined" type="submit">
            <b>VALIDER</b>
          </Button>
        </Stack>
      )}
    </>
  );
};

export default HiddenTextInput;
