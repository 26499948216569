import axios from "axios";
import { PCA, scopes } from "../msal/msal";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "X-Version": `${process.env.REACT_APP_API_VERSION}`,
  },
});

api.interceptors.request.use(
  async (config) => {
    const accounts = PCA.getAllAccounts();
    if (accounts.length > 0) {
      try {
        await PCA.initialize();
        await PCA.handleRedirectPromise();
        const token = await PCA.acquireTokenSilent({
          scopes: scopes,
          account: accounts[0],
        });
        config.headers["Authorization"] = `Bearer ${token.accessToken}`;
      } catch (error) {
        PCA.clearCache();
        localStorage.clear();
        PCA.logoutRedirect({
          authority: process.env.REACT_APP_AZURE_BASE_URL,
          postLogoutRedirectUri: `/`,
        });
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
