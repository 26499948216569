import { createSlice } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";
import { getUserFromMail } from "../users/user-slice";

// SLICE
export const administratorsSlice = createSlice({
  name: "administratorsSlice",
  initialState: {
    administratorsList: [],
    loading: true,
    error: null,
    success: null,
  },
  reducers: {
    setAdministratorsList: (currentSlice, action) => {
      currentSlice.administratorsList = action.payload.items.filter(
        (user) => user.isAdministrator,
      );
    },
    setLoading: (currentSlice, action) => {
      currentSlice.loading = action.payload;
    },
    setError: (currentSlice, action) => {
      currentSlice.error = action.payload;
    },
    removeAdministratorFromList: (currentSlice, action) => {
      currentSlice.administratorsList = currentSlice.administratorsList.filter(
        (user) => user.externalUserId !== action.payload,
      );
    },
    addAdministratorIntoList: (currentSlice, action) => {
      if (
        currentSlice.administratorsList.find(
          (administrator) =>
            administrator.externalUserId === action.payload.externalUserId,
        )
      ) {
        currentSlice.error = "Cet utilisateur est déjà administrateur.";
      } else {
        currentSlice.administratorsList.push(action.payload);
      }
    },
  },
});

export const {
  setAdministratorsList,
  setLoading,
  setError,
  removeAdministratorFromList,
  addAdministratorIntoList,
} = administratorsSlice.actions;

export const fetchUsers = () => async (dispatch) => {
  dispatch(setError(null));
  dispatch(setLoading(true));
  try {
    await api
      .get(`${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.USER}`)
      .then((response) => {
        dispatch(setAdministratorsList(response.data));
        dispatch(setLoading(false));
      });
  } catch (error) {
    dispatch(setError(error.message));
    console.error(error);
  }
};

export const updateAdministratorRole =
  (externalUserId, isAdministrator) => async (dispatch) => {
    try {
      await api
        .put(
          `${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.USER}/${externalUserId}`,
          { externalUserId: externalUserId, isAdministrator: isAdministrator },
        )
        .then((response) => {
          if (isAdministrator) {
            dispatch(addAdministratorIntoList(response.data.item));
          } else {
            dispatch(deleteAdministrator(externalUserId));
          }
        });
    } catch (error) {
      dispatch(setError(error.message));
    }
  };

export const addAdministrator = (email) => async (dispatch) => {
  try {
    let user = await dispatch(getUserFromMail(email));
    if (user) {
      // Get user from externalUserId
      await api
        .get(
          `${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.USER}/${user.id}`,
        )
        .then(async (response) => {
          if (response.data.item) {
            dispatch(
              updateAdministratorRole(response.data.item.externalUserId, true),
            );
          } else {
            await api
              .post(
                `${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.USER}`,
                {
                  externalUserId: user.id,
                  isAdministrator: true,
                },
              )
              .then((response) => {
                dispatch(addAdministratorIntoList(response.data.item));
              });
          }
        });
    } else {
      dispatch(
        setError("Cet utilisateur n'existe pas dans la base de données."),
      );
    }
  } catch (error) {
    dispatch(setError(error.message));
    console.error(error);
  }
};

export const deleteAdministrator = (externalUserId) => async (dispatch) => {
  try {
    // Get user from externalUserId
    await api
      .delete(
        `${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.USER}/${externalUserId}`,
      )
      .then(async (response) => {
        if (!response.data.success) {
          response.data.messages.forEach((message) => {
            console.error(message.message);
          });
        } else {
          dispatch(removeAdministratorFromList(externalUserId));
        }
      });
  } catch (error) {
    console.error(error);
  }
};
