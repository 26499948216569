import { useEffect, useCallback } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { scopes } from "../utils/msal/msal";

// Check if user is already logged : if so, user is redirect to the current page, otherwise, he's redirected to Azure.
const useAuthCheck = (redirectPage?: string): void => {
  const isAuthenticated: boolean = useIsAuthenticated();
  const navigate = useNavigate();
  const { instance, inProgress } = useMsal();

  // To fix Typescript undefined process.env error
  const filteredScopes: string[] = scopes.filter(
    (scope: string | undefined): scope is string => scope !== undefined,
  );

  const handleSign = useCallback(async () => {
    await instance.loginRedirect({
      authority: process.env.REACT_APP_AZURE_BASE_URL,
      scopes: filteredScopes,
    });
  }, [instance]);

  useEffect(() => {
    const checkLogged = async (): Promise<void> => {
      if (inProgress === "none" && !isAuthenticated) {
        await handleSign();
        redirectPage && navigate(`/${redirectPage}`);
      }
    };
    checkLogged();
  }, [navigate, redirectPage, inProgress, isAuthenticated, handleSign]);
};

export default useAuthCheck;
