import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ListItem, ListItemText, Typography } from "@mui/material";

import { AppDispatch, RootState } from "../../store/store";
import { CenterModel } from "../../models/CenterModel";
import { fetchCenterData } from "../../store/centers/center-slice";

import ListCard from "../common/ListCard";

type Props = {
  pid?: string;
  organizationCentersList: CenterModel[];
};

const CentersList = ({ pid, organizationCentersList }: Props) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const error: null | string = useSelector(
    (store: RootState) => store.ORGANIZATION.error,
  );
  const loading: boolean = useSelector(
    (store: RootState) => store.ORGANIZATION.loading,
  );

  if (pid && organizationCentersList) {
    organizationCentersList = organizationCentersList.filter(
      (center) => center.pid !== pid,
    );
  }

  const navigateToCenter = (code: string) => {
    dispatch(fetchCenterData(code));
    navigate("/center");
  };

  return (
    <ListCard
      list={organizationCentersList}
      isLoading={loading}
      error={error}
      noneText="Aucun autre centre affilié à l'organisation"
    >
      {pid && organizationCentersList?.length > 0 ? (
        <Typography fontWeight="bold">
          {"Autres centres de l'organisation :"}
        </Typography>
      ) : null}
      {organizationCentersList &&
        organizationCentersList.map((center) => (
          <ListItem
            key={organizationCentersList?.indexOf(center)}
            onClick={() => navigateToCenter(center.pid)}
            sx={{
              cursor: "pointer",
              "&:hover": {
                color: "primary.main",
                backgroundColor: "rgba(46,30,76,0.1)",
              },
            }}
          >
            <ListItemText sx={{ textAlign: "center" }}>
              <Typography>{center.name}</Typography>
            </ListItemText>
          </ListItem>
        ))}
    </ListCard>
  );
};

export default CentersList;
