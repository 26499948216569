import { AlertColor } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";

export interface AlertModel {
  id: string;
  type: AlertColor;
  message: string;
}

interface AlertState {
  alerts: AlertModel[];
}

const initialState: AlertState = {
  alerts: [],
};

export const alertSlice = createSlice({
  name: "alertSlice",
  initialState,
  reducers: {
    setAlert: (currentSlice, action) => {
      currentSlice.alerts.push(action.payload);
    },
    clearAlert: (state, action) => {
      // Filter alerts to remove the one with specific id
      state.alerts = state.alerts.filter(
        (alert) => alert.id !== action.payload,
      );
    },
  },
});

export const { setAlert, clearAlert } = alertSlice.actions;
