import { createSlice } from "@reduxjs/toolkit";

import { api } from "../../utils/api";
import { CONFIG_API } from "../../data/config.API";

// SLICE

export const centersSlice = createSlice({
  name: "centersSlice",
  initialState: {
    centersList: [],
    loading: true,
    error: null,
  },
  reducers: {
    setCenters: (currentSlice, action) => {
      currentSlice.centersList = action.payload;
      currentSlice.loading = false;
    },
    // addCenter: (currentSlice, action) => {
    //   // currentSlice.centersList
    // },
    setLoading: (currentSlice, action) => {
      currentSlice.loading = action.payload;
    },
    // setError: (currentSlice, action) => {
    //   currentSlice.error = action.payload;
    // },
  },
});

const { setCenters, setLoading } = centersSlice.actions;

// ACTIONS

export const fetchCenters = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    await api
      .get(`/${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.CENTER}`)
      .then((response) => {
        dispatch(setCenters(response.data.items));
        dispatch(setLoading(false));
      });
  } catch (err) {
    return console.error(err.message);
  }
};

// export const postNewCenter =
//   (organizationPid, formData) => async (dispatch) => {
//     dispatch(setLoading(true));
//     try {
//       await api
//         .post(
//           `/${CONFIG_API.CTONLINE_ADMINISTRATION}/${CONFIG_API.ORGANIZATION}/${organizationPid}/${CONFIG_API.CENTER}`,
//           {
//             formData,
//           },
//         )
//         .then((response) => {
//           dispatch(addCenter(response.data));
//           dispatch(setLoading(false));
//         });
//     } catch (err) {
//       setError(err.message);
//     }
//   };
