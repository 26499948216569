import { Stack, Typography } from "@mui/material";

import { CenterModel } from "../../models/CenterModel";

import CentersList from "../center/CentersList";
import Card from "../common/Card";

type Props = {
  data: {
    centersList: CenterModel[];
  };
  organizationCode: string;
};

const OrganizationInfo = ({ data, organizationCode }: Props) => {
  return (
    <Stack>
      {data.centersList?.length > 0 && (
        <>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "primary.main" }}
          >
            {organizationCode}
          </Typography>
          <Card padding="0.5rem 0" title="Centres de l'Organisation">
            <CentersList organizationCentersList={data.centersList} />
            {/* <Divider />
            <Button onClick={() => setShowCreateForm(true)} color="secondary" variant="contained" sx={{ margin: "1rem auto", width: "fit-content", fontWeight: "bold" }}>Ajouter un centre</Button> */}
          </Card>
        </>
      )}
    </Stack>
  );
};

export default OrganizationInfo;
