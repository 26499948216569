import { Box, Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";

type FlowbiteColors =
  | "gray"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "teal"
  | "blue"
  | "indigo"
  | "purple"
  | "pink";

interface Props {
  currentColor: ColorPickerModel;
  setCurrentColor: any;
}

export interface ColorPickerModel {
  name: FlowbiteColors;
  hexColor700: string;
  hexColor50: string;
  frenchName: string;
}

export const colorVariants: ColorPickerModel[] = [
  {
    name: "blue",
    hexColor700: "#1A56DB",
    hexColor50: "#EBF5FF",
    frenchName: "Bleu",
  },
  {
    name: "red",
    hexColor700: "#C81E1E",
    hexColor50: "#FDF2F2",
    frenchName: "Rouge",
  },
  {
    name: "orange",
    hexColor700: "#B43403",
    hexColor50: "#FFF8F1",
    frenchName: "Orange",
  },
  {
    name: "yellow",
    hexColor700: "#8E4B10",
    hexColor50: "#FDFDEA",
    frenchName: "Jaune",
  },
  {
    name: "green",
    hexColor700: "#046C4E",
    hexColor50: "#F3FAF7",
    frenchName: "Vert",
  },
  {
    name: "teal",
    hexColor700: "#036672",
    hexColor50: "#EDFAFA",
    frenchName: "Turquoise",
  },
  {
    name: "indigo",
    hexColor700: "#4338ca",
    hexColor50: "#F0F5FF",
    frenchName: "Indigo",
  },
  {
    name: "purple",
    hexColor700: "#6C2BD9",
    hexColor50: "#F6F5FF",
    frenchName: "Violet",
  },
  {
    name: "pink",
    hexColor700: "#BF125D",
    hexColor50: "#FDF2F8",
    frenchName: "Rose",
  },
  {
    name: "gray",
    hexColor700: "#374151",
    hexColor50: "#f3f4f6",
    frenchName: "Gris",
  },
];

const ColorPicker = ({ currentColor, setCurrentColor }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [chosenColor, setChosenColor] = useState(currentColor);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button onClick={handleClick} sx={{ textTransform: "none" }}>
        <Stack
          bgcolor={chosenColor.hexColor50}
          flexDirection={"row"}
          gap={1}
          width={"8rem"}
          border={`1px solid ${chosenColor?.hexColor700}`}
          justifyContent={"center"}
          padding={0.5}
          alignItems={"center"}
        >
          <Box
            sx={{
              width: "1.25rem",
              height: "0.875rem",
              bgcolor: chosenColor.hexColor700,
            }}
          ></Box>
          <Typography sx={{ color: chosenColor.hexColor700 }}>
            {chosenColor.frenchName}
          </Typography>
        </Stack>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {colorVariants.map((color) => (
          <MenuItem
            key={color.name}
            onClick={() => {
              setChosenColor(color);
              setCurrentColor(color);
              setAnchorEl(null);
            }}
            onMouseEnter={() => {
              setCurrentColor(color);
            }}
            onMouseLeave={() => {
              setCurrentColor(chosenColor);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Stack
              bgcolor={color.hexColor50}
              flexDirection={"row"}
              gap={1}
              width={"10rem"}
              border={`1px solid ${color?.hexColor700}`}
              alignItems={"center"}
              paddingY={0.5}
              paddingX={1}
            >
              <Box
                sx={{
                  width: "1.25rem",
                  height: "0.875rem",
                  bgcolor: color.hexColor700,
                }}
              ></Box>
              <Typography sx={{ color: color.hexColor700 }}>
                {color.frenchName}
              </Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ColorPicker;
