import { useSelector } from "react-redux";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { Button, Divider, Stack, Typography } from "@mui/material";

import { RootState } from "../../store/store";
import { CenterModel } from "../../models/CenterModel";
import InfoIcon from "../../assets/images/icons/info.svg";
import OrganizationIcon from "../../assets/images/icons/organization.svg";

import UsersList from "./UsersList";
import CentersList from "./CentersList";
import Card from "../common/Card";
import InfoItem from "./InfoItem";
import ActivitiesIcons from "../common/ActivitiesIcons";
import LoadingProgress from "../common/LoadingProgress";
import AccessInfo from "./AccessInfo";

type Props = {
  data: CenterModel;
};

const CenterInfo = ({ data }: Props) => {
  const organizationCentersList: CenterModel[] = useSelector(
    (store: RootState) => store.ORGANIZATION.currentOrganization.centersList,
  );
  const centerState = useSelector((store: RootState) => store.CENTER);
  const navigate: NavigateFunction = useNavigate();

  return (
    data && (
      <Stack>
        <Stack direction="column" justifyContent="center" gap={0.5}>
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{ color: "primary.main" }}
          >
            {data.name}
          </Typography>
          <Typography variant="h6">
            {data.codePts ? data.codePts : data.centerCode}
          </Typography>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={{ xs: 0, md: 5 }}>
          <Stack>
            <UsersList />
          </Stack>
          <Stack margin={"auto"}>
            <Card icon={InfoIcon} title="Informations" padding={2}>
              <InfoItem
                title="Adresse"
                data={[data.address1, data.postalCode, data.city]}
              >
                {data.address1}
                <br />
                {data.postalCode} {data.city}
              </InfoItem>
              <InfoItem title="Activités" data={data.activities}>
                <ActivitiesIcons
                  activities={data.activities}
                  width={30}
                  justifyContent="center"
                />
              </InfoItem>
              <InfoItem title="Site Web" data={[data.website]} isLastItem>
                <Link
                  to={data.website}
                  target="_blank"
                  style={{ maxWidth: "40rem", overflowWrap: "break-word" }}
                >
                  {data.website}
                </Link>
              </InfoItem>
            </Card>
            <Card
              icon={OrganizationIcon}
              title="Organisation"
              padding="0.5rem 0"
            >
              {data.organizationCode && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mb: "0.5rem", fontWeight: "bold" }}
                  onClick={() =>
                    navigate("/organization", {
                      state: {
                        organizationPid: data.organizationPid,
                        organizationCode: data.organizationCode,
                      },
                    })
                  }
                >
                  {data.organizationCode}
                </Button>
              )}
              <Typography fontWeight="bold">Accès :</Typography>
              {!centerState.loading ? (
                <AccessInfo data={data} />
              ) : (
                <LoadingProgress hideMessage size={"1.5rem"} />
              )}
              <Divider sx={{ margin: "0.5rem" }} />
              <CentersList
                organizationCentersList={organizationCentersList}
                pid={data.pid}
              />
            </Card>
          </Stack>
        </Stack>
      </Stack>
    )
  );
};

export default CenterInfo;
