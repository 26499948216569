export const CONFIG_API = {
  CTONLINE: "CTOnline",
  CTONLINE_ADMINISTRATION: "CTonlineAdministration",
  CTONLINE_CONNECT: "CTonlineConnect",
  ORGANIZATION: "Organization",
  CENTER: "Center",
  ACCOUNT: "Account",
  ADMIN: "Admin",
  USER: "User",
  LOGIN: "login",
  EXTERNAL_ACCOUNT: "ExternalAccount",
  REGISTRER_ACCOUNT: "RegisterAccount",
  PASSWORD: "password",
  PARTNER: "Partner",
  MAIL: "mail",
  VL: "VL",
  PL: "PL",
  L: "MOT",
  VL_PL: "VL|PL",
};
